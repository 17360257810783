<template>
  <div class="Table EDTable">
    <div class="table-responsive">
      <b-table
        ref="ed-table"
        :items="items"
        :fields="fields"
        thead-class="Table__header EDTable__header"
        tbody-tr-class="Table__row EDTable__row"
        hover
      >
        <template #cell(app_logo)="data">
          <div class="dataCell">
            <img :src="data.value || defaultCompanyLogo" alt="image-service" />
          </div>
        </template>
        <template #cell(app_name)="data">
          <div class="dataCell">
            <span class="app-name">{{ data.value }}</span>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div class="dataCell">
            <span>{{ getDateFromString(data.value) }}</span>
          </div>
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
  </div>
</template>

<script>
import { employeeManagementService } from '@/services/employee-management-service'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'
import { stringToDateJapan } from '@/utils/date-utils'
import TablePaging from '@/components/commons/common-table-paging'

export default {
  name: 'EDTable',
  components: { TablePaging },
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
      fields: employeeManagementService.getServiceFields(),
      items: [],
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
    }
  },
  watch: {
    perPage() {
      this.reloadData()
    },
    currentPage() {
      this.reloadData()
    },
  },
  async created() {
    await this.reloadData()
  },
  methods: {
    getDateFromString(value) {
      return stringToDateJapan(value)
    },
    async reloadData() {
      const { items, total } = await employeeManagementService.getServicesByEmployeeId(
        this.employeeId,
        this.perPage,
        this.currentPage
      )
      this.items = items
      this.total = total
    },
  },
}
</script>
