<template>
  <Layout>
    <page-layout
      page-titles="従業員情報詳細"
      :top-layout="true"
      :bread-crumb-items="breadcrumbItems"
    >
      <template #top-content>
        <div class="EDPage__employee">
          <div class="code">
            {{ employeeCode }}
          </div>
          <div class="info">
            <div class="info--image">
              <img :src="formData.avatar || defaultAvatar" alt="avatar-image" />
            </div>
            <div class="info--title">
              <div class="text-dark font-size-12 font-custom">従業員ID:</div>
              <div class="text-dark font-size-12 font-custom">メールアドレス：</div>
              <div class="text-dark font-size-12 font-custom">部署：</div>
              <div class="text-dark font-size-12 font-custom">役職：</div>
            </div>
            <div class="info--content">
              <div class="font-detail">{{ formData.user_code }}</div>
              <div class="font-detail">{{ formData.email }}</div>
              <div class="font-detail">{{ formData.department_name }}</div>
              <div class="font-detail">{{ formData.position_name }}</div>
            </div>
            <div class="info--title">
              <div class="text-dark font-size-12 font-custom">ステータス:</div>
            </div>
            <div class="info--content">
              <div :class="`font-detail MTStatus ${getStatusClass(formData.status)}`">
                {{ getStatusLabel(formData.status) }}
              </div>
            </div>
            <div v-if="!isCustomerUser" class="info--button">
              <b-button variant="primary" @click="showEditDialog">
                <span>編集</span>
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template #table-content>
        <e-d-table v-if="formData.id" :employee-id="formData.id" />
        <c-modal :id="dialogId" title="従業員情報編集" :buttons="dialogButtons">
          <e-m-form
            :form-data.sync="editFormData"
            :trigger-validate.sync="triggerValidate"
            :is-valid.sync="isValid"
          />
        </c-modal>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import { employeeManagementService } from '@/services/employee-management-service'
import EDTable from '@/components/tables/employee-detail-service-table'
import CModal from '@/components/commons/common-modal'
import EMForm from '@/components/forms/employee-mangement-form'
import { ALERT_TYPE, DEFAULT_IMAGE, USER_STATUS } from '@/utils/constants'
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash-es/get'
import pick from 'lodash-es/pick'
import { MESSAGES } from '@/utils/messages'
import { isHttpLink } from '@/utils/object-helpers'

export default {
  name: 'EmployeeDetailPage',
  components: { PageLayout, EDTable, Layout, CModal, EMForm },
  data() {
    return {
      employeeId: '',
      dialogId: 'ed-dialog',
      dialogTitle: '従業員情報編集',
      formData: {},
      defaultAvatar: DEFAULT_IMAGE,
      onAddOrEditConfirm: () => {
        return {}
      },
      triggerValidate: false,
      isValid: false,
      requiredEditKeys: [
        'id',
        'user_code',
        'email',
        'name',
        'department_id',
        'position_id',
        'status',
        'avatar',
      ],
      editFormData: {},
      previousPage: [
        {
          text: '部署・従業員管理',
          href: '/customer/employee-management',
        },
        {
          text: '従業員',
          href: '/customer/employee-management',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['user_status']),
    ...mapGetters('user', ['currentUser', 'isCustomerUser']),
    employeeCode() {
      const { user_code, name } = this.formData
      return user_code + ' - ' + name
    },
    breadcrumbItems() {
      return [
        {
          text: '部署・従業員管理',
          href: '/customer/employee-management',
        },
        {
          text: '従業員',
          href: '/customer/employee-management',
        },
        {
          text: this.formData.name,
          active: true,
        },
      ]
    },
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  beforeCreate() {
    if (!this.$route.params.id) {
      this.$router.push('/404')
    }
  },
  async created() {
    this.employeeId = this.$route.params.id
    this.formData = await employeeManagementService.getItemsById(this.employeeId)
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    getStatusClass(id) {
      return id === USER_STATUS.ACTIVE ? 'MTStatus__active' : 'MTStatus__inactive'
    },
    getStatusLabel(id) {
      return get(this.user_status.filter((val) => val.id === Number(id))[0], 'name', '')
    },
    showEditDialog() {
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.editFormData, this.requiredEditKeys)
            if (isHttpLink(formData.avatar)) {
              delete formData.avatar
            }
            const { success, data } = await employeeManagementService.editItem(formData)
            if (!success) {
              const messages = this.getErrorAlert(data)
              this.showErrorMessages(messages)
              return
            }
            this.$bvModal.hide(this.dialogId)
            this.formData = await employeeManagementService.getItemsById(this.employeeId, true)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG02,
            })
          }
        }, 150)
      }
      this.editFormData = { ...this.formData }
      this.$bvModal.show(this.dialogId)
    },
    getErrorAlert(items) {
      const result = []
      if (JSON.stringify(items).indexOf('user_code') !== -1) {
        result.push(MESSAGES.CUSTOMER_MT.KH08)
      }
      if (JSON.stringify(items).indexOf('email') !== -1) {
        result.push(MESSAGES.CUSTOMER_MT.KH09)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },

    showErrorMessages(messages) {
      messages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
  },
}
</script>
